$('.articleScroll').slick({
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 1000,
    infinite: false,
    // pauseOnFocus: false,    //フォーカスで止めない  
    // pauseOnHover: false,    //ホバーで止めない  
    // pauseOnDotsHover: false,//ドットで止めない  
    dots: false,
    centerMode:true,        //両サイドを表示
    centerPadding:"20%",    //両サイドをどれぐらい表示するか
    responsive: [
        {
          breakpoint: 500,
          settings: {
            centerPadding:"15%",
          }
        }
      ]
});